/* eslint-disable */
import {
    ProductListQuery as SourceProductListQuery
} from 'SourceQuery/ProductList.query';
import { Field, Fragment } from 'Util/Query';
import BrowserDatabase from 'Util/BrowserDatabase';
import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import { NONE_SORT_OPTION_VALUE } from 'Route/SearchPage/SearchPage.config';
import { SORT_DIRECTION_TYPE } from 'Route/CategoryPage/CategoryPage.config';

/** @namespace Bellinger/Query/ProductList/Query */
export class ProductListQuery extends SourceProductListQuery {
    _getAttributeOptionsFields(isVariant) {
        const {
            isPlp = false
        } = this.options;

        if (isPlp && isVariant) {
            return [];
        }

        return [
            this._getAttributeOptionsField()
        ];
    }

    _getProductFields() {
        const { requireInfo, isSingleProduct, notRequireInfo } = this.options;

        // do not request total count for PDP
        if (isSingleProduct || notRequireInfo) {
            return [
                this._getItemsField()
            ];
        }

        // for filters only request
        if (requireInfo) {
            return [
                this._getSortField(),
                this._getAggregationsField()
            ];
        }

        return [
            'total_count',
            this._getItemsField(),
            this._getPageInfoField(),
            this._getWidgetCondition()
        ];
    }

    _getWidgetCondition() {
        return new Field('widget_conditions')
            .addFieldList([
                'attribute_code',
                'attribute_name',
                'attribute_value'
            ]);
    }

    _getProductInterfaceFields(isVariant, isForLinkedProducts = false, isForWishlist = false) {
        const {
            isPlp = false,
            isSingleProduct,
            noAttributes = false,
            noVariants = false,
            noVariantAttributes = false,
            is_search = false
        } = this.options;

        if (is_search) {
            return [
                'uid',
                'id',
                'sku',
                'name',
                'url',
                'url_key',
                'type_id',
            ]
        }
        // set option to always request images for product variants if they're requested for wishlist
        if (isForWishlist) {
            this.options.isForWishlist = true;
        }

        // Basic fields returned always
        const fields = [
            'uid',
            'id',
            'sku',
            'name',
            'type_id',
            'special_price',
            'is_bestseller_product',
            'polarized_lens_product_id',
            this._getPriceRangeField()
        ];

        if (isForWishlist) {
            fields.push(
                'bel_parent_sku',
                'bellinger_brand_label'
            )
        }

        fields.push(
            this._getBelStock(),
            'bellinger_brand',
            'bellinger_configurable_select'
        )

        // Additional fields, which we want to return always, except when it's variants on PLP (due to hugh number of items)
        if (!(isPlp && isVariant) || isForWishlist) {
            fields.push(
                this._getProductSmallField(),
                'special_from_date',
                'special_to_date',
                this._getShortDescriptionField(),
            );
        }

        // if it is normal product and we need attributes
        // or if, it is variant, but we need variant attributes or variants them-self
        if ((!isVariant && !noAttributes) || (isVariant && !noVariantAttributes && !noVariants)) {
            fields.push(this._getAttributesField(isVariant));
        }



        // to all products (non-variants)
        if (!isVariant) {
            fields.push(
                'url',
                this._getCategoriesField(),
                this._getUrlRewritesFields(),
            );

            // if variants are not needed
            if (!noVariants) {
                fields.push(this._getConfigurableProductFragment());
            }
        }

        if(isVariant && isPlp){
            fields.push(
                this._getSimpleProductFragment()
            )
        }

        // prevent linked products from looping
        if (isForLinkedProducts) {
            fields.push(this._getProductLinksField());
        }

        // additional information to PDP loads
        if (isSingleProduct) {
            fields.push(
                this._getDescriptionField(),
                this._getMediaGalleryField(),
                this._getSimpleProductFragment(),
                this._getProductImageField(),
                this._getShortDescriptionField()
            );

            // for variants of PDP requested product
            if (!isVariant) {
                fields.push(
                    'meta_title',
                    'meta_keyword',
                    'meta_description',
                    this._getCategoriesField(),
                );
            }
        }

        return fields;
    }

    getAlsoMayLikeProducts(options) {
        const {currentPage, pageSize, brand} = options;
        // Need to reset options for this query, so we always get all the data we need
        this.options = {
            ...this.options,
            is_search: false,
            isSingleProduct: true,
            noVariants: false,
            notRequireInfo: false
        }

        return new Field('belGetAlsoMayLikeProducts')
            .addArgument('currentPage', 'Int', currentPage)
            .addArgument('pageSize', 'Int', pageSize)
            .addArgument('brand', 'Int', brand)
            .addFieldList([this._getItemsField(),'total_count'])
    }

    _getSimpleProductFragment() {
        const { isPlp = false } = this.options;
        const fields = ['bellinger_brand'];

        if(isPlp) {
            fields.push(
                'bellinger_brand_label',
                'bellinger_front_material',
                'bellinger_front_material_combined',
                'bellinger_gender',
                'bellinger_hot_pick',
                'bellinger_launch_date',
                'bellinger_primary_color_group',
                'bellinger_product_group_name',
                'bellinger_secondary_color_group',
                'bellinger_shape_category',
                'bellinger_temple_material'
            )
        }

        return new Fragment('SimpleProduct')
            .addField(this._getPlpSmallImage())
            .addFieldList(fields);
    }

    _getPlpSmallImage() {
        return new Field('plp_small_image')
            .addFieldList(['path', 'url'])
    }

    _getBelStock() {
        return new Field('bel_stock')
            .addFieldList([
                'available_qty',
                'color_marker',
                'stock_status',
                'stock_text'
            ])
            .addField(this._getProductStock());
    }

    _getProductStock() {
        return new Field('product_stock')
            .addFieldList([
                'brand',
                'commited',
                'instock',
                'nextdelivery',
                'ordered',
                'sku',
                'warehousecode'
            ]);
    }

    _getCartProductField() {
        return new Field('product')
            .addFieldList([
                'id',
                'sku',
                'stock_status',
                'salable_qty',
                this._getStockItemField(),
                this._getProductThumbnailField(),
                this._getAttributesField(true, true),
                this._getBelStock()
            ]);
    }

    _getArgumentsMap() {
        const { requireInfo } = this.options;
        const filterArgumentMap = this._getFilterArgumentMap();

        return {
            currentPage: { type: 'Int!' },
            pageSize: {
                type: 'Int!',
                handler: (option) => (requireInfo ? 1 : option)
            },
            search: {
                type: 'String!',
                handler: (option) => option.replace(/\+/g, ' ')
            },
            // Modified the sorting to render the newest products
            sort: {
                type: 'ProductAttributeSortInput',
                handler: ({ sortKey, sortDirection }) => {
                    if (sortKey === NONE_SORT_OPTION_VALUE || (!sortDirection && !sortKey)) {
                        return {};
                    }

                    return { [sortKey]: sortDirection || SORT_DIRECTION_TYPE.asc };
                }
            },
            filter: {
                type: 'ProductAttributeFilterInput!',
                handler: (initialOptions = {}) => {
                    // add customer group by default to all requests
                    const { group_id } = BrowserDatabase.getItem(CUSTOMER) || {};

                    const options = {
                        ...initialOptions,
                        customerGroupId: group_id ? group_id.toString() : '0'
                    };

                    const {
                        customFilters: { category_id } = {}
                    } = options;

                    /**
                     * Remove category ID from select, if there is a custom filter
                     * of category already selected in filtering options.
                     */
                    if (category_id) {
                        // eslint-disable-next-line fp/no-delete
                        options.categoryIds = undefined;
                    }

                    // Replacing the bellinger_primary_color_group and bellinger_secondary_color_group in order to use
                    // the custom made 'OR' condition
                    let bellingerColorsSelected = {}
                    if(options.customFilters && Object.entries(options.customFilters).length){
                        Object.entries(options.customFilters).map(([key, value]) => {
                            if(key === 'bellinger_primary_color_group'){
                                delete options.customFilters[key]
                                bellingerColorsSelected = {
                                    ...bellingerColorsSelected,
                                    ['bellinger_primary_color_group_or']: value
                                }
                            }

                            if(key === 'bellinger_secondary_color_group'){
                                delete options.customFilters[key]
                                bellingerColorsSelected = {
                                    ...bellingerColorsSelected,
                                    ['bellinger_secondary_color_group_or']: value
                                }
                            }

                            return bellingerColorsSelected;
                        })

                        options.customFilters = {...options.customFilters, ...bellingerColorsSelected}
                    }

                    const parsedOptions = Object.entries(options).reduce(
                        (acc, [key, option]) => {
                            // if there is no value, or if the key is just not present in options object
                            if (!option || !filterArgumentMap[key]) {
                                return acc;
                            }

                            return { ...acc, ...filterArgumentMap[key](option) };
                        },
                        {}
                    );

                    return parsedOptions;
                }
            }
        };
    }
}

export default new ProductListQuery();
